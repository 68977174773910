@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-sofort-load-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;

  &__content {
    text-align: center;
    margin: 18px 0;
  }

  &__amount {
    font-weight: bold;
  }

  &__img {
    background-image: url(./load-sofort-5euro.svg);
    display: inline-block;
    width: 201px;
    height: 122px;
  }
}
