@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-screen-keyboard-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 194px;

  &__button {
    flex-basis: 33%;

    &:nth-child(n + 4) {
      // 2nd row onwards
      margin-top: 14px;

      &:not {
        margin-left: 22px;
      }
    }
  }
}
