@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-deposit-method-card {
  &__upper-side {
    display: flex;
    flex-grow: 1;
  }

  &__lower-side {
    top: 0;
  }

  &__refund-text {
    line-height: 0.1;
    font-size: $rew-font-size-20;
    color: $rew-feijoa;
    display: block;
    margin-bottom: 8px;
  }

  &__options-display {
    line-height: 14px;
    margin-top: 2px;
  }

  &__warning {
    color: #f26565;
    line-height: 14px;
    margin-top: 5px;
  }

  &__bold {
    font-weight: 600;
  }

  --disabled {
    opacity: 0.5;
  }
}
