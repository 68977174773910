@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-mdl-checkbox {
  display: flex;

  &__input-container.mdl-checkbox {
    width: 24px;

    &:not(.is-checked) {
      .mdl-checkbox__focus-helper {
        background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS45NyA5LjMzIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2UyZTJlMjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPnY1PC90aXRsZT48cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTEuOTcgMS43OCAxMC4yIDAgNC40MiA1Ljc4IDEuNzggMy4xNCAwIDQuOTIgMi42NCA3LjU2IDIuNjQgNy41NiA0LjQyIDkuMzMgNC40MiA5LjMzIDQuNDIgOS4zMyA2LjIgNy41NiA2LjIgNy41NiAxMS45NyAxLjc4Ii8+PC9zdmc+');
        background-size: 68% 68%;
        background-repeat: no-repeat;
        background-position: 50%;
      }
    }
  }
}

.mdl-checkbox__focus-helper {
  display: none !important;
}

.mdl-checkbox__box-outline.mdl-checkbox__box-outline,
.mdl-checkbox.is-checked .mdl-checkbox__box-outline.mdl-checkbox__box-outline {
  border-color: $rew-primary;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline.mdl-checkbox__tick-outline {
  background: #7815f9 url('/assets/images/mdl/tick.svg?embed');
}
