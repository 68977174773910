@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-warning-card {
  // background: $rew-white;
}

.rew-warning-card__content {
  display: flex;
  align-content: row;
  justify-content: space-between;
}

.rew-warning-card__content__left {
  flex-grow: 0;
  margin: auto;
  margin-right: 15px;
  min-width: 30px;

  .support__contact-us__phone {
    border: none;
    padding-top: 10px;
    .rew-contact__icon {
      line-height: 50px;
    }
  }
}

.rew-warning-card__content__right {
  flex-grow: 1;
  flex-shrink: 1;
}

.rew-warning-card__content__right__header {
}

.rew-warning-card__content__right__warning {
  color: $rew-carnation;
}
