@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$table-font-size: 13px;
$table-line-height: 1.3;
$table-lines-to-show: 2;

rew-wallet-item .item {
  margin: auto;
}

@mixin rew-wallet-item {
  $p: &;

  // Override ionic .item
  &#{&} {
    display: flex;
    height: 83px;
    border: 0;
    padding: 0 16px;

    color: $rew-gray;
    background-color: inherit;

    &--frozen {
      color: #c6c6c6;
    }
  }

  &__subject {
    min-width: 0%;
    flex: 0 1 auto;
  }

  &__subject-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subject-details {
    position: absolute;
    top: 50%;
    max-width: calc(100% - 133px);
    transform: translateY(-50%);
    display: inline-block;
  }

  &__date {
    align-self: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
  }

  &__body {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    // This prevents element from expanding over 100% of flex contanier
    min-width: 0%;
  }

  &__right-section {
    min-width: 84px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__content {
      display: flex;
      width: 104px;
      position: relative;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;
    }
  }

  &__title {
    font-size: 15px;
    color: $rew-gray;
  }

  &__pay-button {
    display: inline-block;
    transition: all 0.5s ease-in-out;

    &.ng-leave {
      position: absolute;
      z-index: -1;
    }

    &.ng-leave.ng-leave-active {
      transform: scale(0, 0);
      opacity: 0;
    }

    .rew-order-item-base__sum.ng-leave + & {
      display: none;
    }
  }

  &__sum {
    text-align: right;
    position: relative;
    margin-left: 5px;

    &--anim {
      transition: 0.5s linear all;

      &.ng-enter {
        top: 20px;
      }

      &.ng-enter.ng-enter-active {
        top: 0px;
      }

      &.ng-leave {
        top: 0px;
      }

      &.ng-leave.ng-leave-active {
        top: -20px;
      }
    }
  }

  &__sum-title {
    font-size: 15px;
    color: $rew-gray;
  }

  &__running-balance {
    margin-top: 9px;

    font-size: 10px;
    color: $rew-silver-chalice;
    line-height: 0.93;
  }
}

.item-divider.item-divider {
  background: $rew-alto;
  color: $rew-gray;

  font-size: 14px;
  padding-bottom: 6px;

  .rew-order-list__orders__table__history-order:not(:first-child) & {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cecece+0,e0e0e0+32 */
    background: #cecece; /* Old browsers */
    background: -moz-linear-gradient(top, #cecece 0%, #e0e0e0 32%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #cecece 0%, #e0e0e0 32%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #cecece 0%,
      #e0e0e0 32%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cecece', endColorstr='#e0e0e0', GradientType=0); /* IE6-9 */
  }
}
