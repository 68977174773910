@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-page-header {
  padding-top: 3px;
  padding-bottom: $rew-page-header--padding;

  color: $rew-subheader-color;
  font-size: $rew-subheader-font-size;
}

.rew-page-header__text {
  @at-root h5#{&} {
    margin: 0;
    padding: 0;
    font-size: $rew-subheader-font-size;
    color: $rew-subheader-color;
  }

  display: block;
}

.rew-page-header--center {
  text-align: center;
}

.rew-page-header--bold {
  font-weight: bold;
}
