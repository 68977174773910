/*
Using naming conventions from:
http://chir.ag/projects/name-that-color/#FBFFBA
 */

$rew-black: #000000;

$rew-primary-dark: #321879;
$rew-primary-dark-active: #4a2d99;

$rew-primary: #7815f9;
$rew-primary-active: #5c0dc4;
$rew-primary-hover: #680ede;
$rew-primary-disabled: #efe3fe;
$rew-primary-rgb: '120,21,249'; //#7815F9;

$rew-utility: #007aff;

// purples
$rew-affair: #59267c;

// whites
$rew-white: #ffffff;
$rew-wild-sand: #f6f6f6;

// blues
$rew-chathams-blue: #14587c;
$rew-cornflower: #9fd3ee;
$rew-cerulean: #00bff3;
$rew-turquoise-blue: #007aff;
$rew-bright-turquoise: #00d4e9;
$rew-malibu: #60bde9;
$rew-jungle-mist: #b6ccd7;
$rew-tarawera: #0a354c;
$rew-chambray: #3b5998; // facebook
$facebook-blue: $rew-chambray;
$rew-light-blue-rgb: '99, 186, 233'; //#63BAE9;
$rew-picton-blue: #43abe6;
$rew-mint-tulip: #d1eaf7;
$rew-blizzard-blue: #afdbf2;
$rew-tropical-blue: #d0e9f8;

// grays:
$rew-alto: #f4f4f4; // backgrounds
$rew-gallary: #ebebeb; // backgrounds
$rew-gray: #6f6f6f; // texts
$rew-another-gray: #898989;
$rew-silver: #cccccc; // arrow, contact text,
$rew-silver-transparent: rgba(204, 204, 204, 0.5);
$rew-silver-chalice: #acacac; //  wallet-items

//greens:
$rew-emerald: #43d854;
$rew-pixie-green: #bfdcbc;
$rew-de-york: #7cc576;
$rew-feijoa: #8fd48c;
$rew-amulet: #7ea67c;
$rew-malachite: #00d564;

//reds:
$rew-deep-blush: #e6787d;
$rew-dust-storm: #e6cfd0;
$rew-carnation: #f26565;
$rew-red: rgb(222, 50, 38);
$rew-valecia: #dc4e41;
$rew-postal-office-color: #ca151d;
$rew-gmt-background-color: #ffff;
$rew-gmt-color: #2E3084;
$rew-sf-color: #ec1f39;

// Oranges:
$rew-ronchi: #f1be4f;

//
//TODO - adi - change these colors accoridng to the naming convention
$rew-black-light: rgba(0, 0, 0, 0.3);
$rew-light-blue: unquote('rgb(#{$rew-light-blue-rgb})');

// Themes
$go: #00e89f;
$go-hover: #00bf8c;
$go-active: #00d199;
$go-disabled: #def3dd;

$default: #7815f9;
$default-hover: #5c0dc4;
$default-active: #680ede;
$default-disabled: #efe3fe;

$error: #f26565;
$error-hover: #b74545;
$error-active: #d35858;
$error-disabled: #e6cfd0;

$info: #ffc452;
$info-hover: #ed9d00;
$info-active: #efa922;
$info-disabled: #ebebeb;

$support: #172e49;
$support-hover: rgba(255, 255, 255, 0.4);
$support-active: #172e49;

$facebook: #3b5998;
$facebook-hover: #1c3770;
$facebook-active: #2c4e96;
$facebook-disabled: #9eadce;

$bar-dark-bg: $rew-primary-dark;

$family-account-background: #c33cff;
$family-account-background-active: #ac35e0;
