@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-action-card {
  display: block;
}

.rew-action-card {
}

.rew-action-card__content {
  display: flex;

  color: $rew-silver-chalice;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.rew-action-card__content-block-main {
  order: 1;
  flex: 1 0 auto;

  overflow: hidden;
}

.rew-action-card__content-block-side.rew-action-card__content-block-stretch,
.rew-action-card__content-block-main.rew-action-card__content-block-stretch {
  flex-basis: 100%;
}

.rew-action-card__content-block-side {
  order: 2;
  flex: 0 0 auto;
}

.rew-action-card-font {
  font-size: $rew-action-card-font-size;
}

.rew-action-card-small-font {
  font-size: $rew-action-card-font-size-small;
}

.rew-action-card__emblem {
  padding-right: 0px; //iphone bug fix
  padding-left: 0px; //iphone bug fix

  position: absolute;

  border: 0;

  margin-right: $rew-pane-padding-sides;
  font-size: $rew-action-card-font-size + 3px;

  font-family: $rew-font-family-regular;

  color: $rew-cerulean;

  flex-shrink: 0;

  background-color: transparent;

  height: $rew-card-emblem-font-size + 10px;
  width: $rew-card-emblem-font-size + 10px;
}
