@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-beneficiary-details {
  &__button {
    display: block;
    margin: auto;
  }
  &__text {
    text-align: center;
    color: $rew-gray;
    margin-bottom: 15px;
  }
}
