@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-checklist {
  &__item {
    display: flex;
  }

  &__item-check {
    align-self: center;
    // @include v-sign();
  }

  &__item-text {
    margin-left: 10px;
  }
}
