@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-basic-card {
  &__title {
    margin-top: 5px;
    display: block;
  }

  &__body {
    margin-top: 10px;
    display: block;
  }

  &__button {
    margin: auto;
    margin-top: 15px;
    display: block;
  }

  &__img {
    &--mastercard {
      background-image: url(./card-illustration-new.svg);
      display: inline-block;
      width: 46px;
      height: 41px;
    }

    &--activate-card {
      background-image: url(./received-card-new.svg);
      display: inline-block;
      width: 57px;
      height: 43px;
    }

    &--order-card-failed {
      background-image: url(./card-order-failed.svg);
      display: inline-block;
      width: 45px;
      height: 45px;
    }

    &--unknown-deposit {
      background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49"%3E%3Cdefs%3E%3ClinearGradient id="a" x1="50%25" x2="50%25" y1="139.361%25" y2="-10.592%25"%3E%3Cstop offset="0%25" stop-color="%2329E2FF"/%3E%3Cstop offset="100%25" stop-color="%230F8CEF"/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%23FAFAFA" d="M49 24.5C49 38.031 38.031 49 24.5 49S0 38.031 0 24.5 10.969 0 24.5 0 49 10.969 49 24.5"/%3E%3Cpath fill="url(%23a)" d="M19.145 22.166c.028-.1.06-.2.096-.301 0 0 .736-.857 1.524-1.256.788-.4 3.52-1.142 6.304 0C29.854 21.75 31 23.389 31 24.505c0 2.066-.142 3.51-.314 4.5.178.029.314.154.314.304l-.041.456c-.044.49-.066.98-.066 1.472v.454c0 .17-.175.309-.389.309h-.115c-.214 0-.389-.14-.389-.309v-.709 1.01a8.824 8.824 0 0 1-2.978 6.624C26.133 39.407 25.1 40 24 40c-1.1 0-2.133-.593-3.022-1.384A8.823 8.823 0 0 1 18 31.993v-2.59a17.31 17.31 0 0 1-.127-.322.447.447 0 0 0-.262-.081h-.222c-.214 0-.389.139-.389.309l.041.456c.044.49.066.98.066 1.472v.454c0 .17.175.309.389.309h.115c.214 0 .389-.14.389-.309V29.31c0-.09-.05-.172-.127-.228-.437-1.138-.873-2.639-.873-4.094 0-1.563 1.517-2.4 2.024-2.745.042-.028.082-.053.121-.076zM35.76 41.355c1.9.439 3.241 2.103 3.241 4.02 0 .897-.741 1.625-1.656 1.625H10.656C9.74 47 9 46.272 9 45.375c0-1.917 1.34-3.581 3.241-4.02.734-.17 1.565-.362 2.463-.572a27.174 27.174 0 0 0 5.203-1.782c.171.197.343.394.53.578 1.168 1.147 2.366 1.728 3.562 1.728 1.195 0 2.393-.581 3.562-1.728.187-.184.359-.381.53-.579a27.15 27.15 0 0 0 5.205 1.783c.898.21 1.729.403 2.463.572z"/%3E%3Crect width="14" height="14" x="17" y="2" fill="%238FD48C" rx="1"/%3E%3Cpath fill="%238FD48C" d="M24 11l4 4-4 4-4-4z"/%3E%3Ctext fill="%23FFF" font-family="ProximaNova-Regular, Proxima Nova" font-size="11"%3E%3Ctspan x="19.677" y="13"%3E€%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/svg%3E');
      display: inline-block;
      width: 49px;
      height: 49px;
    }

    &--unknown-witheld-deposit {
      background-image: url(./unknown-witheld-deposit.svg);
      display: inline-block;
      width: 49px;
      height: 49px;
    }
  }
}
