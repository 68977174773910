@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-ribbon-label {
  $arrow-width: $rew-label-offset;

  z-index: 1;

  &__label {
    font-weight: bold;
    color: $rew-white;

    background-color: $rew-feijoa;
    padding: 0 $rew-pane-padding-sides;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    z-index: -1;

    border-left: $arrow-width solid $rew-amulet;
    border-top: $arrow-width solid transparent;
    border-bottom: $arrow-width solid transparent;
    border-right: $arrow-width solid transparent;
    right: -$arrow-width;
    bottom: -$arrow-width;
  }
}
