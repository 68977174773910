@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-amounts {
  &__transaction-details {
    margin-top: $rew-pane-vertical-margin;
  }

  &__promotion-card {
    margin-top: $rew-pane-vertical-margin;
  }

  &__buttons-container {
    margin-top: 16px;
  }

  &_method-card {
    margin-top: 13px;
  }

  &_disabled-send-money-card{
    margin-bottom: 13px;
  }
}

.animate-show {
  opacity: 1;
  max-height: 500px;
}

//.animate-show.ng-hide-add,
.animate-show.ng-hide-remove {
  transition: all linear 0.4s;
  overflow: hidden;
}

.animate-show.ng-hide {
  max-height: 0;
  opacity: 0;
}
