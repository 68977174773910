@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
rew-calculator {
  display: block;
}

.rew-calculator {
  font-family: $rew-font-family-regular;
  position: relative;

  & .rew-calculator__seperator-aligner {
    display: none;
  }
}

.rew-calculator--horizontal-theme {
  display: flex;

  &.rew-calculator {
    .rew-input-additions__item {
      color: $rew-silver-chalice;
    }
  }

  .rew-calculator__cell {
    // for line-height auto, which is the case for mdl textfields
    $font-vertical-spacing: 2px;
    box-sizing: border-box;

    // calculating from the top of the label.
    padding-top: (10px - $font-vertical-spacing);

    // calculating from textfield input line.
    padding-bottom: calc-mdl-textfield-input-underline-bottom-distance(20px);
    padding-right: $rew-pane-padding-sides - 1px;
    padding-left: $rew-pane-padding-sides - 1px;
    background-color: $rew-calculator-background-color;

    flex: 1;

    &.rew-calculator__cell-right {
      // hack for the received
      flex: 1.1;
    }

    &:first-child {
      border-bottom-left-radius: $rew-pane-border-radius;
      border-top-left-radius: $rew-pane-border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $rew-pane-border-radius;
      border-top-right-radius: $rew-pane-border-radius;
    }
  }

  &.rew-calculator--focus-left {
    .rew-calculator__cell-left {
      background-color: $rew-calculator-focus-background-color;
    }
    .rew-calculator__seperator-left {
      display: none;
    }
  }

  &.rew-calculator--focus-right {
    .rew-calculator__cell-right {
      background-color: $rew-calculator-focus-background-color;
    }

    .rew-calculator__seperator-right {
      display: none;
    }
  }

  .rew-calculator__cell-data {
    z-index: 20;
  }

  .rew-calculator__seperator-aligner {
    flex: 0;
    z-index: 10;
    display: block;
  }

  .rew-calculator__seperator-stretcher {
    position: absolute;
    height: 100%;
  }

  .rew-calculator__seperator-container {
    height: 100%; // iphone, iOS 7, safari
    min-width: 40px; // Galaxy S4 and ios 7
    max-height: 200px; // iphone, ios 7, safari

    margin-left: -50%;
  }

  .rew-calculator__seperator {
    height: 100%;
  }
}
