@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-deposit-card {
  &__upload-id-button {
    width: 100%;
  }

  & .mdl-button.mdl-button--rewire-full {
    // long text
    font-size: 22px;
    text-transform: none;
  }
}

.rew-deposit-card__content {
  &.rew-pane {
    padding: 0px;
  }
}

$rew-deposit-card__content__sub-container--padding-top: 10px;

.rew-deposit-card__content__sub-container {
  padding: $rew-deposit-card__content__sub-container--padding-top $rew-pane-padding-sides;
  border-top: 1px solid $rew-wild-sand;
}

.rew-deposit-card__content__header {
  padding: $rew-deposit-card__content__sub-container--padding-top 0px;
}

// applied on the object after the header
.rew-deposit-card__content__header-shadow {
  padding-top: 1.5 * $rew-deposit-card__content__sub-container--padding-top;
  background: #ffffff;
  /* Old browsers */
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f2f2f2 0%, #ffffff 20%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f2f2f2 0%, #ffffff 20%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F2F2F2', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */
}

$rew-deposit-card-button-height: 34px;
$rew-deposit-card-button-font-size: 20px;

.rew-deposit-card {
  .mdl-button--rewire-narrow {
    .mdl-button.mdl-button--rewire-full,
    &.mdl-button.mdl-button--rewire-full {
      height: $rew-deposit-card-button-height;

      line-height: $rew-deposit-card-button-font-size;
      font-size: $rew-deposit-card-button-font-size;
    }
  }
}
.rew-deposit-card__content__actions.rew-deposit-card__content__sub-container {
  padding-bottom: $rew-pane-padding-sides;
}

.rew-deposit-card__content__actions__buttons-container__row {
  display: flex;

  > * {
    flex-grow: 1;
  }

  > * + * {
    margin-left: 15px;
  }

  &:not(:first-child) {
    margin-top: $rew-pane-padding-sides;
  }

  .mdl-button--colored[disabled][disabled] {
    color: white;
    background: rgba(99, 186, 233, 0.25);
    box-shadow: none;
  }
}

.rew-deposit-card__content__actions__multiple-options-text {
  padding-bottom: 10px;
}

.rew-deposit-card__content__actions__buttons-container__row__check-box {
  height: $rew-deposit-card-button-height;
  flex-basis: $rew-deposit-card-button-height;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;

  border-radius: 5px;
  margin-right: 10px;

  line-height: $rew-deposit-card-button-height - 2px;
  font-size: $rew-deposit-card-button-font-size;
}

.rew-deposit-card__content__actions__buttons-container__row__check-box--number {
  font-weight: bold;

  color: $rew-light-blue;
  border: dashed 2px $rew-light-blue;
}

.rew-deposit-card__content__actions__buttons-container__row__check-box--checked {
  color: white;
  background: rgba(99, 186, 233, 0.25);
}

.rew-deposit-card__account-details-button {
  color: $rew-light-blue;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
