@mixin clearfix() {
  &:before,
  &:after {
    display: table;
    content: '';
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

@mixin inline-img($src, $width, $height) {
  background-image: $src;
  display: inline-block;
  width: $width;
  height: $height;
}

@mixin padded-inline-img($src, $width, $height, $padding) {
  @include inline-img($src, $width, $height);

  background-size: $width $height;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  padding: $padding;
}

@mixin circle-clickyness {
  transition: background 0.15s linear;
  border-radius: 100%;

  &:active {
    background-color: rgba(169, 169, 169, 0.75);
  }
}

@mixin v-sign() {
  background-image: url(./icons/v.svg);
  display: inline-block;
  width: 12px;
  height: 9px;
}

@mixin button-theme($theme, $bg-color, $hover-color, $active-color, $disabled-color) {
  &#{&}--#{$theme} {
    @content;
    background-color: $bg-color;
  }

  &#{&}--#{$theme}:hover:not(:active) {
    background-color: $hover-color;
  }

  &#{&}--#{$theme}:active {
    background-color: $active-color;
  }

  &#{&}--#{$theme}#{&}--disabled[disabled][disabled]:not(#{&}--altError) {
    background-color: $disabled-color;
  }
}
