@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-create-order-summary {
  font-size: $rew-font-size-20;
  color: $rew-gray;

  &__receiver,
  &__bank {
    color: $rew-gray;
  }

  &__header-label {
    line-height: 20px;
  }

  &__confirm {
    text-align: center;
  }

  &__receiver {
    margin: 0;

    font-weight: bold;
    font-size: $rew-font-size-30;
    line-height: 20px;
  }

  .rew-rates__first-row {
    font-size: $rew-font-size-20;
  }

  &__bank {
    margin: 5px 0 0;
  }

  &__refund {
    font-size: $rew-font-size-15;
    padding-top: 14px;

    &--green {
      color: $rew-feijoa;
      font-weight: bold;
    }
  }

  &__clause {
    display: flex;
    justify-content: space-between;

    &-label {
      margin-top: auto;
      margin-bottom: auto;
    }

    &-value {
      max-width: 157px;
      text-align: right;

      &--smaller {
        font-size: $rew-font-size-15;
      }
    }

    & + & {
      margin-top: 15px;
    }

    &--smart-transfer {
      .rew-create-order-summary__clause-label {
        font-weight: bold;
      }

      .rew-create-order-summary__eta-ul {
        transform: translateY(-50%);
      }
    }
  }

  &__numbers {
    line-height: 1;
  }

  &__eta-container {
    text-align: right;
    overflow: hidden;

    // The container contains 2 lines (height 2em), but has overflow hidden to hide the 2nd line
    // Thus line height 1em is required
    height: 1em;
  }

  &__eta-ul {
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &__eta-li {
    &--smart-transfer {
      font-weight: bold;

      // This worksaround the bug in chrome that seems to show part of the element which is
      // overflowing despite being overflow: hidden (seems to be an issue with <1px overflow)
      // Also improves the animation because the line-height is larger
      line-height: calc(2em + 1px);
    }
  }

  &__terms-input {
    margin-top: 10px;
  }

  &__smart-transfer {
    position: relative;
  }

  &__smart-transfer-new-label {
    position: absolute;
    top: 0;
    right: 0;

    font-size: $rew-font-size-20;
    font-weight: bold;
    color: $rew-deep-blush;
  }

  &__agreement {
    margin-top: 12px;
    text-align: left;
  }

  &__agreement-high-risk {
    margin-bottom: 12px;
    text-align: left;
  }

  &__smart-transfer {
    max-height: 0;

    &.rew-pane__block {
      padding-top: 0;
      padding-bottom: 0;
    }

    $ribbon: '.rew-smart-transfer__new-label';
    $smartTransfer: '.rew-smart-transfer';

    &:not(&--open) {
      &.rew-pane__block {
        border-top: 0;
      }

      #{$ribbon} {
        display: none;
      }
    }

    &#{&}--open {
      // animation: expand 2s ease;
      max-height: 150px;
      padding-top: 15px;
      padding-bottom: 15px;

      #{$ribbon} {
        animation: ribbon 1.5s cubic-bezier(0.18, 0.89, 0.29, 1.58);
      }
    }

    &#{&}--closed {
      padding-top: 0;
      padding-bottom: 0;
      max-height: 0;
    }
  }
}

@keyframes expand {
  0% {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  44% {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  100% {
    max-height: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@keyframes ribbon {
  0% {
    transform: translateY(-50%) scale(0);
  }
  53% {
    transform: translateY(-50%) scale(0);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
