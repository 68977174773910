@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$button-width: 183px;
$half-button-width: $button-width / 2;
$button-height: 36px;
$half-button-height: $button-height / 2;

.rew-image-uploader form {
  text-align: center;
}

.upload-kyc-doc-form__upload-id.upload-kyc-doc-form__upload-id--short .rew-image-uploader__image {
  width: 214px;
  height: 84px;
}

.rew-image-uploader__button {
  position: absolute;
  top: calc(50% - #{$half-button-height});
  right: calc(50% - #{$half-button-width});

  .rew-button {
    width: $button-width;
  }

  &#{&}--top-right {
    position: absolute;
    top: 5px;
    right: 5px;

    .rew-button {
      width: 56px;
      height: 56px;
      border-radius: 100%;
    }
  }
}

.rew-image-uploader__image-container {
  //height: 150px;
  text-align: center;
  vertical-align: middle;

  //border: 3px dashed gray;
  background-image: url('/assets/images/receipt_example.svg');
  background-repeat: no-repeat;

  //background-position: 50% 50%;
  //background-size: 50px 50px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; // to push the spinny

  // &.ng-invalid {
  //   border-color: red;
  // }

  &.rew-image-uploader__image-container--file {
    //   border: none;
    //   background-image: url("/assets/images/receipt_uploaded.svg");
    //   background-color: rgb(250,250,250);
  }

  &.rew-image-uploader__image-container--spinny {
    //  background-image: none;
  }

  &#{&}--error {
    border: solid 2px $rew-deep-blush;
    border-radius: 6px;
  }
}

.rew-image-uploader__image {
  width: 100%; // iphone bug

  &#{&}--error {
    padding: 5px;
    margin: 5px;
    border: dashed 2px $rew-deep-blush;
    border-radius: 6px;
    width: calc(100% - 10px);
  }
}

/* The starting CSS styles for the enter animation */
.fade.ng-enter {
  transition: 0.5s linear all;
  // transition-delay: 0.1s;
  opacity: 0;
}

/* The finishing CSS styles for the enter animation */
.fade.ng-enter.ng-enter-active {
  opacity: 1;
}

.fade.ng-hide {
  transition: 1s linear all;
  opacity: 0;
}

.rew-image-uploader__button.mdl-button--raised,
.rew-image-uploader__button.mdl-button--raised.mdl-button.mdl-button--fab {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

.mdl-button--rewire-top-right.mdl-button--fab {
  top: 0;
  right: 0;
  position: absolute;
}

.rew-image-uploader__button__text {
  margin-left: 5px;
  vertical-align: middle;
  font-size: 14px;
}

.rew-image-uploader__button__image {
  height: 50%;
  vertical-align: middle;
}

.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;

  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  visibility: visible !important;
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1.1);
  }
  80% {
    -webkit-transform: scale(0.9);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

.spin {
  -webkit-animation: spinAnimation 1.4s infinite linear;
  animation: spinAnimation 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes spinAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
