@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-input-additions {
  position: absolute;
  top: ($rew-input-text-padding + $rew-input-text-vertical-spacing + 1px);
  left: 0;
  right: 0;
  bottom: 0;

  text-align: right;

  pointer-events: none;
}

.rew-input-additions__item {
  font-family: $rew-font-family-regular;

  padding-right: 5px;
  margin-right: -2px;
  vertical-align: text-bottom;

  &:last-child {
    padding-right: 0px;
  }

  .rew-input-additions.rew-input-additions--positive & {
    color: $rew-text-color-positive;
  }

  .rew-input-additions.rew-input-additions--bold & {
    font-family: $rew-font-family-bold;
  }

  .rew-input-additions__item--clickable {
    pointer-events: auto;
  }
}
