@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-rates-card {
  text-align: center;

  &__title {
    display: block;
    text-align: center;
    color: $rew-another-gray;
  }

  &__link {
    display: inline-block;
  }
}
