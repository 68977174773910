@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-wallet-item-amount {
  $p: &;
  font-size: 15px;

  &--credit {
    color: $rew-de-york;
  }

  &--frozen {
    color: #c6c6c6;
  }
}
