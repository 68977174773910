@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-multipart-modal {
  $button-padding: 14px;
  padding: 30px;

  &__back-btn {
    @include padded-inline-img(
      url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 7.49 12.85"%3E%3Cpath d="M7.49 11.78L2 6.43 7.49 1l-1-1L0 6.43l6.47 6.42 1-1.07z"/%3E%3C/svg%3E'),
      8px,
      13px,
      $button-padding
    );
    @include circle-clickyness;

    cursor: pointer;
    position: absolute;
    left: -$button-padding / 2;
    top: -$button-padding / 2;
  }

  &__content {
    padding-top: 20px;
  }

  &__location {
    margin-top: 12px;

    // Because of how the ionic popup is built (has a 16px padding + 2px from the c-modal),
    // this is necessary to get 12px margin on both sides. Will be solved when popups are reworked
    margin-bottom: -6px;

    display: block;
    text-align: center;
    line-height: 0;
  }
}
