@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.c-modal {
  position: relative;
  margin: 2px 4px;

  &__close-btn {
    $button-padding: 16px;
    @include padded-inline-img(
      url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 10.05 10.04"%3E%3Cpath d="M10.05.86A.24.24 0 0 0 10 .69Q9.56.28 9.37.07a.25.25 0 0 0-.37 0L5.2 3.88a.22.22 0 0 1-.35 0L1 .07A.28.28 0 0 0 .86 0a.28.28 0 0 0-.17.07L.07.69A.24.24 0 0 0 0 .86.22.22 0 0 0 .07 1L3.9 4.85A.22.22 0 0 1 4 5a.24.24 0 0 1-.07.18L.07 9a.22.22 0 0 0-.07.18.24.24 0 0 0 .07.18q.42.4.62.62a.28.28 0 0 0 .17.02.28.28 0 0 0 .14 0l3.85-3.85a.22.22 0 0 1 .35 0L9 10a.25.25 0 0 0 .35 0l.65-.65a.24.24 0 0 0 .07-.18A.22.22 0 0 0 10 9L6.15 5.19A.24.24 0 0 1 6.08 5a.22.22 0 0 1 .07-.16L10 1a.19.19 0 0 0 .07-.16z"/%3E%3C/svg%3E'),
      11px,
      11px,
      $button-padding
    );
    @include circle-clickyness;

    cursor: pointer;
    position: absolute;
    right: -$button-padding / 2;
    top: -$button-padding / 2;

    // z-index > 0 to show infront of titles etc
    z-index: 1;
  }
}
