@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-upload-kyc-doc__image-uploader-container {
  margin-top: 10px;
  margin-bottom: -8px;

  max-width: 300px; // for desktop
  margin: auto;
}

.rew-upload-kyc-doc__top-text-container {
  margin-bottom: 5px;
}

.rew-upload-kyc-doc__image-uploader-container__explanation-text__red-dot {
  height: 5px;
  margin-right: 3px;
}

.rew-over-populdated-modal.rew-upload-id-modal {
  &.rew-modal.popup-container .popup {
    padding-top: 2px;
    margin-top: 0px;
  }
}

.rew-upload-id-modal {
  height: $rew-modal-height;
  position: relative;
}
