@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$dotDiameter: 8px;

.rew-location {
  &__dot {
    display: inline-block;
    width: $dotDiameter;
    height: $dotDiameter;
    border-radius: 100%;
    background-color: $rew-gallary;
    transition: 0.5s ease-out all;

    &#{&}--on {
      transition: 0.5s ease-in all;
      background-color: $rew-primary;
    }

    & + & {
      margin-left: 7px;
    }
  }
}
