@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
///////////////////////////////////////
// This file contains variable overrides for mdl based on rewire's setup file.
///////////////////////////////////////

// NO OVERRIDE SHOULD GO ABOVE THIS LINE.

// Fixed white bar issue in phone 5 with ios 11
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  /* iPhone 5 and 5s only */
  .rewire-external-extensions.platform-ios.platform-cordova:not(.fullscreen)
    .bar-header:not(.bar-subheader) {
    height: 56px;
  }

  .rewire-external-extensions.platform-ios.platform-cordova:not(.fullscreen) .bar-header {
    padding-left: calc(constant(safe-area-inset-left) + 5px);
    padding-left: calc(env(safe-area-inset-left) + 5px);
    padding-right: calc(constant(safe-area-inset-right) + 5px);
    padding-right: calc(env(safe-area-inset-right) + 5px);
  }

  .rewire-external-extensions.platform-ios.platform-cordova:not(.fullscreen)
    .bar-header
    .buttons:last-child {
    right: calc(constant(safe-area-inset-right) + 5px);
    right: calc(env(safe-area-inset-right) + 5px);
  }

  .rewire-external-extensions.platform-ios.platform-cordova:not(.fullscreen) .has-tabs,
  .platform-ios.platform-cordova:not(.fullscreen) .bar-footer.has-tabs {
    bottom: calc(constant(safe-area-inset-bottom) + 48px);
    bottom: calc(env(safe-area-inset-bottom) + 48px);
  }

  .rewire-external-extensions.platform-ios.platform-cordova:not(.fullscreen) .has-header,
  .platform-ios.platform-cordova:not(.fullscreen) .bar-subheader {
    top: 56px;
  }

  .rewire-external-extensions.platform-ios.platform-cordova:not(.fullscreen) .has-subheader {
    top: 112px;
  }

  .rewire-external-extensions.platform-ios.platform-cordova:not(.fullscreen)
    .has-header.has-tabs-top {
    top: 104px;
  }
}
