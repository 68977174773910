@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-subheader {
  font-size: $rew-font-size-15;
  line-height: 1.2;
  color: $rew-another-gray;
  font-weight: bold;
}
