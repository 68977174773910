@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
@media screen {
  .rew-order-summary.rew-order-summary .print {
    display: none;
  }
}

@media print {
  .tab-nav.tab-nav.tabs.tabs {
    visibility: hidden;
    display: none;
  }
  .rewire-nav-bar.rewire-nav-bar {
    visibility: hidden;
    display: none;
  }
  .rew-pane.rew-pane {
    visibility: hidden;
    display: none;
  }
  .rew-page-header.rew-page-header {
    visibility: hidden;
    display: none;
  }
  .rew-order-accordion.rew-order-accordion {
    visibility: hidden;
    display: none;
  }
  .rew-settings-view.rew-settings-view {
    visibility: hidden;
    display: none;
  }
  .mdl-button.mdl-button {
    visibility: hidden;
    display: none;
  }

  .overflow-scroll.overflow-scroll {
    overflow-y: hidden;
  }

  rew-android-strip {
    display: none;
  }

  .rew-history__rew-order-list {
    display: none;
  }

  .hide-on-print {
    display: none;
  }

  button {
    visibility: hidden;
    display: none;
  }

  * {
    color: #000 !important;
    //visibility: hidden;
  }

  .rew-page.rew-page {
    width: 100%;
    max-width: 100%;
  }

  .print {
    table {
      margin-bottom: 10px;
    }
    .table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
    }
    th,
    td {
      padding: 5px;
    }
  }

  .rew-order-summary {
    position: absolute;
    top: 0;
    padding: 0px;
    left: 0;
    right: 0;
  }
}
