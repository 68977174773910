@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-contact-us {
  display: inline-block;
  visibility: hidden;
  background-color: #556767;
  border-radius: 100px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  padding: 0 20px;
  height: 48px;
  cursor: pointer;

  > svg {
    height: 48px;
    width: 28px;
  }

  &__text {
    color: $rew-white;
    padding-left: 5px;
  }
}
