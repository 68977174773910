@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-link-container {
  margin: 15px 0;
  width: 100%;
  text-align: center;

  a {
    text-decoration: none;
  }
}
