@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-history-order {
  &__fee {
    font-size: $rew-font-size-15;
    line-height: 10px;
  }
}

.rew-history-order__copy-btn .mdl-button,
.download-btn .mdl-button {
  color: $rew-light-blue;
}

.rew-history-order__copy-btn,
.download-btn {
  text-align: center;
  padding: 0px 5px;
}

.rew-history-order__copy-btn {
  margin-top: 15px;
}

.rew-order__bottom-container {
  margin-top: 8px;
}

.rew-order__bottom-container__details-section {
  margin-top: 10px;
}

.rew-order__bottom-container__status-label {
  text-transform: capitalize;
}

.rew-history-order__update-order-state-to-active-button {
  &.mdl-button.mdl-button--rewire-full {
    // long text
    font-size: 25px;
    text-transform: none;
  }
}
