@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-home-element__button-container {
  text-align: center;

  margin: 10px 0px;
}

.rew-home-element__balance {
  .rew-balance {
    border-bottom: 1px solid $rew-gallary;
  }
}

.rew-home-element__content-containter__bubble {
  border-radius: 5px;
  padding: 10px 4px;
}

.rew-home-element__content-containter__rew-man-container {
  max-width: 350px;
  margin: auto;
}

.rew-home-element__content-containter__rew-man-container__rewire-man {
  background-repeat: no-repeat;
  max-width: 300px;
  background-position-x: 50%;
  margin: auto;
  padding-top: 45px;
}

.rew-home-element__button-container__button.mdl-button--raised.mdl-button--colored:hover,
.rew-home-element__button-container__button.mdl-button--raised.mdl-button--colored {
  background-color: $rew-primary;
}
