@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
@mixin order-status-scheme {
  &--canceled {
    color: $rew-silver-chalice;
  }
  &--rejected {
    color: $rew-deep-blush;
  }
  &--in-process {
    color: $rew-turquoise-blue;
  }
  &--completed {
    color: $rew-de-york;
  }
  &--awaiting-deposit {
    color: $rew-carnation;
  }
  &--awaiting-payment {
    color: $rew-ronchi;
  }
  &--invalid {
    color: $rew-carnation;
  }
}

.rew-order-status {
  font-size: $rew-font-size-10;
  line-height: 0.93;
  width: 265px;
  height: 12px;
  overflow: hidden;
  position: relative;

  &__content {
    position: absolute;
  }

  &__status {
    position: absolute;
    transition: 0.5s linear all;

    &.ng-enter {
      top: 9px;
    }

    &.ng-enter.ng-enter-active {
      top: 0px;
    }

    &.ng-leave {
      top: 0px;
      transform: scale(0, 0);
    }

    &.ng-leave.ng-leave-active {
      top: -9px;
    }
  }

  &__status {
    position: absolute;
    left: 39px;
    top: 0;

    &--frozen {
      color: #ff7a5b;
    }
  }

  &__status-label {
    @include order-status-scheme;
    font-weight: bold;
    position: relative;
    min-height: 9px;

    text-transform: capitalize;
  }
}
