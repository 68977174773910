@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.c-subtitle {
  color: $rew-another-gray;
  font-size: $rew-font-size-15;
  font-weight: bold;

  margin: 0;
}
