@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-modal.rew-error-modal {
  &.popup-container .popup {
    padding-top: 12px;
  }
}

.rew-modal-page__submit-button {
  display: block;
  margin: auto;
}
