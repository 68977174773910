@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-table .item:nth-child(2n) {
  background: #f9f9f9;

  .item-content {
    background: transparent;
  }
}

.rew-table-item .item-content.item-content {
  padding: 0px !important;
}

.rew-table-item .row.row {
  // override ionic row
  padding: 0;
  height: 60px;

  cursor: pointer;
}

.rew-table-item__row {
  display: flex;
  justify-content: space-between;
}

// for the table rows
.gray-background {
  background: #f9f9f9;
}

.item-divider.item-divider {
  background: $rew-alto;
  color: $rew-gray;

  font-size: 14px;
  padding-bottom: 6px;

  .rew-table__history-order:not(:first-child) & {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cecece+0,e0e0e0+32 */
    background: #cecece; /* Old browsers */
    background: -moz-linear-gradient(top, #cecece 0%, #e0e0e0 32%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #cecece 0%, #e0e0e0 32%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #cecece 0%,
      #e0e0e0 32%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cecece', endColorstr='#e0e0e0',GradientType=0 ); /* IE6-9 */
  }
}

.rew-table-item {
  // override ionic
  &.item {
    border: none;
    padding: 0;
  }
}
