@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$button-width: 18px;

.rew-card-arrow-wrapper {
  $p: &;

  display: flex;
  background-color: inherit;
  cursor: pointer;

  &:not(&--disabled):active {
    background-color: $rew-gallary;
  }

  &#{&}--disabled {
    cursor: default;
  }

  &__body {
    flex: 1px;
    max-width: calc(100% - #{$button-width});
  }

  &__button {
    flex-basis: $button-width;

    display: flex;
    justify-content: center;
  }

  &__button--disabled {
    #{$p}__arrow {
      display: none;
    }
  }

  &__arrow {
    align-self: center;
    color: $rew-silver;

    display: inline-block;
    width: 7px;
    height: 14px;
    margin-right: 16px;
    background-size: 100% 100%;
    background-image: url('./right-arrow.svg');
  }
}
