@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
rew-upload-rejected-doc-card {
  display: block;
}

rew-upload-rejected-doc-card + rew-upload-rejected-doc-card {
  margin-top: 9px;
}

.rew-basic-card__img--upload-rejected-doc {
  background-image: url(./reupload-file-new.svg);
  display: inline-block;
  width: 45px;
  height: 41px;
}
