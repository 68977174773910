@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-close-button {
  $button-padding: 16px;
  @include padded-inline-img(
    url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11"%3E%3Cpath d="M10.05.872a.24.24 0 0 0-.05-.17 11.65 11.65 0 0 1-.63-.62.25.25 0 0 0-.37 0l-3.8 3.81a.22.22 0 0 1-.35 0L1 .082a.28.28 0 0 0-.14-.07.28.28 0 0 0-.17.07l-.62.62a.24.24 0 0 0-.07.17.22.22 0 0 0 .07.14l3.83 3.85a.22.22 0 0 1 .1.15.24.24 0 0 1-.07.18L.07 9.012a.22.22 0 0 0-.07.18.24.24 0 0 0 .07.18c.28.267.487.473.62.62a.28.28 0 0 0 .17.02.28.28 0 0 0 .14 0l3.85-3.85a.22.22 0 0 1 .35 0l3.8 3.85a.25.25 0 0 0 .35 0l.65-.65a.24.24 0 0 0 .07-.18.22.22 0 0 0-.07-.17l-3.85-3.81a.24.24 0 0 1-.07-.19.22.22 0 0 1 .07-.16L10 1.012a.19.19 0 0 0 .07-.16l-.02.02z"/%3E%3C/svg%3E'),
    11px,
    11px,
    $button-padding
  );
  @include circle-clickyness;

  cursor: pointer;
}
