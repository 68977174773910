@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
rew-transaction-details {
  display: block;
}

.rew-transaction-details__amounts--promo {
  color: $rew-feijoa;
}
