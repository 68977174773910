@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-promotion-card__content__right {
  color: $rew-gray;
}

.rew-promotion-card__text_url {
  font-size: 12px;
  line-height: normal;
}

.rew-promotion-card__url_no_underline {
  text-decoration: none;
}

.rew-promotion-card__button_like_href {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-size: 12px;
  line-height: normal;
  color: #069;
  text-decoration: none;
  cursor: pointer;
}
