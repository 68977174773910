@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.minor-modal.popup-container {
  background-image: linear-gradient(to bottom, #163357, #000233);

  .popup {
    background-color: $rew-white;

    padding: 0;
    border-radius: 5px;
  }

  .popup-head {
    display: none;
  }

  .c-modal {
    margin: 0;
  }

  .popup-body {
    padding: 14px 12px;
    height: 100%;
  }
}
