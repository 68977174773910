@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-manage-card {
  &.rew-pane {
    padding: 15px 0 0;
  }

  &__title {
    display: block;
    text-align: center;
  }

  &__card-illustration {
    position: relative;
    display: block;

    margin: 15px auto;

    width: 148px;
    height: 171px;
  }

  &__card-illustration-image {
    background-image: url(./new-card-control-illustration.svg);
    display: inline-block;
    width: 148px;
    height: 171px;
    position: relative;
    z-index: 1;
  }

  &__card-illustration-shadow {
    top: 15px;
    left: 10%;
    z-index: 0;
    display: block;
    content: '';
    height: calc(100% - 15px);
    position: absolute;
    width: 80%;
    margin: auto;
    box-shadow: -2px 2px 20px 0px rgba(12, 10, 60, 0.5);
  }

  &__panel-title {
    display: block;
    text-align: center;
  }

  &__panel-item {
    display: block;
    padding: 0 15px;

    & + &,
    &:first-child {
      border-top: solid 1px $rew-gallary;
    }
  }

  &__card-illustration-caption {
    position: absolute;
    z-index: 2;
    color: $rew-white;
    user-select: none;
  }

  &__card-number {
    left: 27px;
    top: 88px;
    font-size: 16px;
    letter-spacing: 2px;
  }

  &__card-expiration {
    letter-spacing: 1px;
    font-size: 12px;

    left: 130px;
    top: 110px;
  }

  &__card-name-and-bic {
    letter-spacing: 1px;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.1;

    left: 18px;
    top: 124px;
  }
}
