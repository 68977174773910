@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$bottom: 0;
$top: -5px;
$size: 5px;

$cicle-base-color: $rew-primary;

$lighten-level1: 7%;
$lighten-level2: 14%;
$lighten-level3: 21%;
$lighten-level4: 28%;

.container-circle {
  justify-content: center;
  position: relative;
  margin: 0 auto;
  display: flex;

  &--white {
    padding: 7.5px;
  }
}

.circle {
  height: $size;
  width: $size;
  margin: 0;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: $bottom;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  & + & {
    margin-left: 4px;
  }

  &--white {
    margin: 3px;
  }
}

#circle1 {
  animation-name: circle1;
}

#circle2 {
  animation-name: circle2;
}

#circle3 {
  animation-name: circle3;
}

#circle4 {
  animation-name: circle4;
}

#circle5 {
  animation-name: circle5;
}

.cc1 {
  background-color: $cicle-base-color;

  &--white {
    background-color: $rew-white;
  }
}

.cc2 {
  background-color: lighten($cicle-base-color, $lighten-level1);

  &--white {
    background-color: $rew-white;
  }
}

.cc3 {
  background-color: lighten($cicle-base-color, $lighten-level2);

  &--white {
    background-color: $rew-white;
  }
}

.cc4 {
  background-color: lighten($cicle-base-color, $lighten-level3);

  &--white {
    background-color: $rew-white;
  }
}

.cc5 {
  background-color: lighten($cicle-base-color, $lighten-level4);

  &--white {
    background-color: $rew-white;
  }
}

@keyframes circle1 {
  0% {
    top: $bottom;
  }
  15% {
    top: $top;
  }
  25% {
    top: $bottom;
  }
}

@keyframes circle2 {
  10% {
    top: $bottom;
  }
  25% {
    top: $top;
  }
  35% {
    top: $bottom;
  }
}

@keyframes circle3 {
  15% {
    top: $bottom;
  }
  30% {
    top: $top;
  }
  40% {
    top: $bottom;
  }
}

@keyframes circle4 {
  20% {
    top: $bottom;
  }
  35% {
    top: $top;
  }
  50% {
    top: $bottom;
  }
}

@keyframes circle5 {
  25% {
    top: $bottom;
  }
  40% {
    top: $top;
  }
  50% {
    top: $bottom;
  }
}
