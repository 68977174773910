@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$padding: 15px;

.rew-disposable-card {
  padding: $padding;

  min-height: 86px;
  position: relative;

  &__texts {
    width: 60%;
  }

  &__close-btn {
    position: absolute;
    right: $padding / 2;
    top: $padding / 2;
  }

  &__cta {
    position: absolute;
    bottom: $padding;
    right: $padding;
    text-align: right;
    max-width: 84px;

    &-text {
      font-size: $rew-font-size-15;
      text-align: center;
      color: $rew-feijoa;
      font-weight: bold;
    }
  }

  &#{&}--facebook {
    .c-subtitle {
      color: $facebook-blue;
    }
  }
}

.card-collapse-in.ng-leave {
  transition: transform 0.3s linear, opacity 0.3s linear,
    max-height 0.2s cubic-bezier(0, 0.01, 0, 1) 0.1s;
  opacity: 1;
  max-height: 100px;
}

.card-collapse-in.ng-leave.ng-leave-active {
  transform: scale(0, 0);
  max-height: 0;
  opacity: 0;
}
