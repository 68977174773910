@import 'colors';

$rew-z-index-10: 1;
$rew-z-index-20: 2;
$rew-z-index-30: 3;

/* Pixel adjustment multiplier */
$rew-pixelRatio: 0.5625;

/* Header */

$rew-header-height: 56px;
$rew-header-horizontal-padding: 22px;
$bar-height: $rew-header-height;
$ios-statusbar-height: 0px;

/* Tabs */

$rew-tabs-height: 48px;
$rew-tabs-vertical-padding: 15px;
$rew-tabs-horizontal-padding: 19px;
$tabs-height: $rew-tabs-height;

/* Fonts */
$rew-font-family-regular: proxima-nova, roboto;
$rew-font-family-bold: proxima-nova-bold, roboto-bold;
$rew-subheader-color: $rew-gray;

$rew-text-color-positive: $rew-de-york;
$rew-font-size-10: 10px;
$rew-font-size-15: 12px;
$rew-font-size-20: 14px;
$rew-font-size-25: 16px;
$rew-font-size-30: 20px;
$rew-card-emblem-font-size: 25px;
$rew-action-card-font-size: 14px;
$rew-card-text-font-size: 13px;
$rew-action-card-font-size-small: $rew-action-card-font-size - 3px;

/* Inputs */

$rew-textfield-input-color: $rew-gray;
$rew-textfield-label-color: $rew-gray;
$rew-dirty-textfield-label-color: $rew-silver-chalice;

$rew-text-field-font-size: 19px;
$rew-input-text-padding: 3px;
/* The vertical spacing should be roughly the font size + the remaining pixels that create the line height. normally it the font size + 4px. unless the line height was changed */
$rew-input-text-vertical-spacing: $rew-text-field-font-size;
$rew-text-floating-label-font-size: 13px;

/* Pages */

$rew-page-header--padding: 11px;
$rew-page-padding-bottom: 8px;
$rew-page-padding-side: 15px;
$rew-subheader-font-size: 17px;

/* Calculator */

$rew-calculator-background-color: $rew-gallary; //$rew-alto;
$rew-calculator-focus-background-color: $rew-white;

/* Button */
$rew-button-background-positive-color: $rew-de-york;
$rew-button-background-negative-color: $rew-carnation;

$rew-button-text-color: $rew-white;
$rew-button-height: 55px;
$rew-button-font-size: 27px;
$rew-button-border-radius: 6px;

/* Pane */
$rew-pane-border-radius: 5px;
$rew-pane-margin-bottom: 5px;
$rew-pane-padding-sides: 15px;
$rew-pane-shadow-size: 2px;
$rew-pane-shadow-color: $rew-black-light;
$rew-pane-box-shadow: 0 $rew-pane-shadow-size 6px 0 $rew-pane-shadow-color;
$rew-pane-vertical-margin: 9px;

/* Modal */
$rew-pane-border-modal-radius: 6px;
$rew-modal-border-radius: 20px;
$rew-pane-padding-modal-sides: 13px;
$rew-modal-height: 426px;

/* Links */
$rew-links-color: $rew-utility;

/* Components */
$rew-label-offset: 5px;
