@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.pane-text {
  color: $rew-another-gray;
  font-size: $rew-card-text-font-size;
  text-align: center;
}
