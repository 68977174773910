@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-fields__group {
  display: flex;
}

.rew-fields__field__and__info__icon__container {
  position: relative;
}

.rew-fields__info__icon {
  position: absolute;
  left: 340px;
  bottom: 23px;
}

.rew-fields__group__field {
  flex-grow: 1;
  flex-basis: 50%;

  margin: auto 5px;
  &:first-child {
    margin-left: 0;
  }

  &:last-child-child {
    margin-right: 0px;
  }

  &--no-expend {
    flex-grow: 0;
    flex-basis: 65px;
  }
}
