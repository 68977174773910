@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-teller-message--rtl {
  font-family: arial;

  .rew-teller-message__text-area {
    text-align: right;
    direction: rtl;
  }

  .mdl-button.rew-teller-message__close-button {
    float: left;
  }
}

.rew-teller-message__text-area {
  min-height: 320px;
  color: #3f3f3f;
}

.mdl-button.rew-teller-message__close-button {
  margin-top: -15px;
  margin-right: -10px;
  min-width: 20px;

  float: right;
}

.mdl-button.rew-teller-message__close-button:hover {
  background: transparent;
}

.rew-teller-message__text-area__header {
  font-size: 35px;
  line-height: 35px;
  padding-top: 25px;
  margin-bottom: 0px;
}

.rew-teller-message__text-area__content {
  font-size: 20px;
  line-height: 25px;
}

.rew-teller-message .mdl-button.mdl-button--primary.rew-teller-message__language-button {
  padding: 0px 10px;
}
