@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$rew-contact--font-size: 60px;

.rew-contact {
  text-align: center;
}

.rew-contact__icon {
  font-size: $rew-contact--font-size;
  line-height: $rew-contact--font-size - 20px;
  cursor: pointer;

  color: $rew-turquoise-blue;
}

.rew-contact__img {
  display: inline-block;
  width: 46px;
  height: 41px;
  cursor: pointer;
  object-fit: contain;
  color: $rew-emerald;
}

.rew-contact__content__lower-text {
  font-size: 10px;
  color: $rew-silver;

  display: block;
}

.rew-contact__content__upper-text {
  font-size: 15px;
  color: $rew-gray;
  margin-top: 5px;

  cursor: pointer;
}
