@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-sepa-card {
  &__cta-text {
    line-height: 1.2;
    height: 30px;
    position: relative;
    top: -4px;
  }
}
