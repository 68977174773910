@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$rew-button-max-width: 356px;

rew-button {
  max-width: $rew-button-max-width;
}

.rew-button {
  $p: &;

  display: inline-block;
  text-align: center;

  cursor: pointer;

  font-weight: bold;
  color: $rew-white;
  font-size: $rew-font-size-20;

  line-height: 36px;
  height: 36px;

  border-radius: 18px;
  background-color: $rew-primary;

  outline: none;
  box-shadow: none;
  border: 0;
  padding: 0;

  &:hover:not(:active):not([disabled]) {
    background-color: $default-hover;
  }

  &:active:not([disabled]) {
    background-color: $default-active;
  }

  &#{&}--disabled[disabled][disabled]:not(&--altError) {
    cursor: default;
    background-color: $default-disabled;

    &#{$p}--error {
      background-color: $rew-dust-storm;
    }
  }

  @include button-theme('go', $go, $go-hover, $go-active, $go-disabled) {
    border-radius: 18px;
  }

  @include button-theme('error', $error, $error-hover, $error-active, $error-disabled);
  @include button-theme('info', $info, $info-hover, $info-active, $info-disabled);
  @include button-theme(
    'facebook',
    $facebook,
    $facebook-hover,
    $facebook-active,
    $facebook-disabled
  );

  &#{&}--negative {
    background-color: rgba(255, 255, 255, 0.2);

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &--expand {
    width: 100%;
    max-width: $rew-button-max-width;
  }

  &--square {
    font-size: $rew-font-size-15;
    width: 84px;
    border-radius: 4px;
    height: 28px;
    line-height: 28px;
    font-weight: bold;
  }

  &#{&}--narrow {
    width: 54px;
  }

  &#{&}--facebook {
    background-color: $facebook-blue;

    &:before {
      content: 'f';
      font-family: 'rewire-icons';
      margin-right: 1px;
      font-size: 15px;
    }
  }

  &#{&}--loading {
    background-color: $rew-blizzard-blue;
  }

  &#{&}--cta {
    border-radius: 5px;
    width: 84px;
    height: 28px;
    line-height: 28px;
    transition: height 0.5s ease-in-out, width 0.5s ease-in-out;
  }

  &#{&}--cta-wide {
    border-radius: 5px;
    height: 28px;
    line-height: 28px;
    transition: height 0.5s ease-in-out, width 0.5s ease-in-out;
    width: 104px;
    font-weight: normal;
  }

  &#{&}--altError {
    background-color: transparent;
    color: $rew-carnation;
    height: 17px;
    line-height: 17px;

    &:active,
    &:hover:not(:active) {
      background-color: transparent;
    }
  }

  &#{&}--orange {
    background-color: $info;

    &:hover:not([disabled]) {
      background-color: $info-hover;
    }
  }

  &#{&}--green {
    background-color: $go;

    &:hover:not([disabled]) {
      background-color: $go-hover;
    }
  }

  &#{&}--text {
    background-color: transparent;
    color: #9a9a9a;
    text-align: left;
    font-size: 12px;
    line-height: 15px;

    &:active,
    &:hover:not(:active) {
      background-color: transparent;
    }
  }

  &#{&}--text-error {
    background-color: transparent;
    color: $error;
    text-align: left;
    font-size: 12px;
    line-height: 15px;

    &:active,
    &:hover:not(:active) {
      background-color: transparent;
    }
  }
}

.mdl-button--raised.mdl-button--raised.mdl-button--colored:hover,
.mdl-button--raised.mdl-button--raised.mdl-button--colored {
  background-color: $rew-primary;
}
