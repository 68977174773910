@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-minor-text {
  color: $rew-gray;
  font-size: $rew-font-size-10;
  display: block;

  margin: 0;
}
