@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.pane-link {
  display: inline;

  &__anchor {
    color: $rew-turquoise-blue;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
}
