@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-info-box {
  padding: 10px 0px;
}

.rew-info-box p {
  // override mdl
  margin-bottom: 0;
}

.rew-info-box__header {
  color: $rew-gray;
}

.rew-info-box__content {
  color: $rew-gray;
  white-space: pre-line;
}
