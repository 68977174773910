@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-rates {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &--dark {
    color: $rew-another-gray;
    font-weight: bold;
  }

  &--bright {
    color: $rew-white;
  }
}

.rew-rates__first-row {
  font-size: 17px;
}

.rew-rates__first-row.rew-rates__first-row * {
  margin: 0;
}

.rew-rates__second-row {
  font-size: 10px;
  line-height: 16px;
}

.rew-rates__promo {
  color: $rew-feijoa;
}
