@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-button-menu-option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px 0;
}
