@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-pane-text {
  color: $rew-another-gray;
  font-size: $rew-font-size-20;

  &--error {
    color: $error;
  }
}
