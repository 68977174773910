@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
// .list .item .item-content {
//   line-height: 38px;
//   padding-top: 0;
//   padding-bottom: 0;

// }
.list .item-content.ng-hide {
  height: 0px;
  //transition: height 1s ease;
}

.content-stable {
}

// .rew-accordion__items-list rew-accordion-item:nth-child(even) .content-stable  {background: $rew-wild-sand}
// .rew-accordion__items-list rew-accordion-item:nth-child(odd) .content-stable {background: $rew-white}

.rew-accordion__items-list rew-accordion-item .rew-accordion-item--disabled.content-stable {
  background: $rew-wild-sand;
}
.rew-accordion__items-list rew-accordion-item .content-stable {
  background: $rew-white;
}
