@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-screen-keyboard-digits {
  display: block;

  &__digit {
    display: inline-block;
    vertical-align: middle;

    & + & {
      margin-left: 15px;
    }
  }
}
