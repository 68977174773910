@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-screen-keyboard-digit {
  display: block;
  height: 23px;
  width: 21px;
  border-bottom: solid 2px $rew-light-blue;

  // line-height: .95em;
  text-align: center;

  &#{&}--error {
    border-color: $rew-deep-blush;
  }

  &#{&}--error#{&}--dot {
    > *:before {
      background-color: $rew-deep-blush;
    }
  }

  &#{&}--go {
    border-color: $rew-feijoa;
  }

  &#{&}--go#{&}--dot {
    > *:before {
      background-color: $rew-feijoa;
    }
  }

  &#{&}--dot {
    position: relative;

    > *:before {
      content: '';
      display: inline-block;
      background-color: $rew-light-blue;
      width: 7px;
      height: 7px;
      border-radius: 100%;

      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      bottom: 7px;
    }
  }
}
