@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-caption {
  font-size: $rew-font-size-20;
  color: $rew-gray;
  text-align: center;

  &#{&}--error,
  &#{&}--error > * {
    color: $rew-carnation;
  }
}
