@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-screen-keyboard {
  display: flex;
  flex-direction: column;

  &__digits {
    margin: auto;
    margin-top: 13px;
  }

  &__buttons {
    display: inline-block;
    margin: auto;

    margin-top: 22px;
  }
}
