@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
// TODO - move the font size into Variables
@mixin rew-info-fonts($element) {
  &.#{$element}--text-tiny {
    font-size: 10px;
  }
  &.#{$element}--text-small {
    font-size: 12px;
    line-height: normal;
  }
  &.#{$element}--text-medium {
    font-size: 14px;
  }
  &.#{$element}--text-large {
    font-size: 16px;
  }
  &.#{$element}--text-huge {
    font-size: 20px;
  }

  &.#{$element}--text-darker-grey {
    color: $rew-gray;
  }
  &.#{$element}--text-lighter-grey {
    color: $rew-silver-chalice;
  }
}

.rew-info {
  color: $rew-gray;
}

.rew-info--center {
  text-align: center;
}

.rew-info--red {
  color: $rew-carnation;
}

.rew-info--bold {
  font-weight: bold;
}

.rew-info--line-break {
  white-space: pre-line;
}

.rew-info__header {
  @include rew-info-fonts('rew-info__header');
  margin-bottom: 5px;
}

.rew-info__body {
  @include rew-info-fonts('rew-info__body');
}
