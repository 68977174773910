@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.bar-subheader-slide-away {
  &.tab-nav.tabs {
    margin-top: -50px;
  }
}

.view-container {
  & .tab-nav.tabs.animate {
    transition: margin-top 0.2s ease;
  }

  & ion-content {
    transition: top 0.2s ease;
  }
}

.has-tabs-bottom {
  bottom: calc(env(safe-area-inset-bottom) + 63px);
}

.keyboard-open {
  #tabs-wrapper {
    display: none;
  }

  .has-tabs-bottom {
    bottom: 0;
  }
}

.send-money-container {
  background-color: #503197;
}

.load-money-container {
  background-color: #503197;
}
