@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-loader {
  $p: &;

  &--absolutely-centered {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translateY(-50%);

    #{$p}__inner {
      margin-left: -50%;
    }
  }

  &__inner:not(.is-upgraded).is-active {
    display: none;
  }
}
