@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-order-list__header {
  padding-left: $rew-page-padding-side;
  padding-right: $rew-page-padding-side;
  border-bottom: 1px solid #f3f3f3;
}

.rew-order-list_orders rew-order:first-child .rew-card.rew-pane {
  // override card default behavior
  margin-top: 0px;
}

.rew-order-list_orders rew-history-order:first-child .rew-card {
  // override card default behavior
  margin-top: 0px;
}

.rew-order-list .rew-page-header {
  padding-top: $rew-page-header--padding;
}

.rew-order-list {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
}

.rew-order-list .list {
  margin: 0;
  padding: 0;
}

.rew-order-list_orders rew-history-order:first-child .rew-card {
  // override card default behavior
  margin-top: 0px;
}
