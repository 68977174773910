@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-wallet-list {
  .item-divider {
    margin: 0;
  }

  &__arrow-wrapper {
    background-color: $rew-white;
  }

  &__arrow-wrapper--even {
    background-color: $rew-wild-sand;
  }

  &__item .item-divider.item-divider {
    border: 0; //overriding ionic border
    margin: 1px; //adding 1px margin to compensate loss of border
  }
}
