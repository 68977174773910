@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$table-font-size: 13px;
$table-line-height: 1.3;
$table-lines-to-show: 2;

@media screen and (max-width: 600px) {
  .rew-order-list__orders__table__cell {
    // used to break lines in mobile
    max-width: 60px;

    &.rew-order-list__orders__table__cell--hide-on-mobile {
      display: none;
    }
  }
}

@media screen and (min-width: 600px) {
  .rew-order-list__orders__table__cell__vertical-aligned-content {
    height: $table-font-size * $table-line-height * $table-lines-to-show;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
  }
}

.rew-order-list__orders__table__cell--center {
  text-align: center;
}

.rew-order-list__orders__table__cell__vertical-aligned-content {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.rew-order-list__orders__table__cell--single-line {
  line-height: $table-line-height * $table-lines-to-show;
}

.rew-order-list__orders__table__cell.rew-order-list__orders__table__cell__date {
  max-width: 60px;
  //padding: 0px 10px;
  flex-basis: 35px;
  flex-grow: 0;
}

.rew-order-list__orders__table .item:nth-child(2n) {
  background: #f9f9f9;

  .item-content {
    background: transparent;
  }
}

.rew-order-table-item .item-content.item-content {
  padding: 0px !important;
}

.rew-order-table-item .row.row {
  // override ionic row
  padding: 0;
  height: 60px;

  cursor: pointer;
}

.rew-solid-color-scheme {
  background: #7cc576;

  &.rejected,
  &.expired {
    background: rgb(239, 102, 83); //#EF6653
  }
  &.canceled {
    background: #d3d3d3;
  }
  &.in-process {
    background: rgb(100, 187, 233); // #64BBE9;
  }
  &.awaiting-deposit {
    background: rgb(241, 190, 79); // #F1BE4F;
  }
}

.rew-half-transparent-color-scheme {
  &.rejected {
    background: rgba(239, 102, 83, 0.1);
  }
  &.in-process {
    background: rgba(100, 187, 233, 0.1);
  }
  &.awaiting-deposit {
    background: rgba(241, 190, 79, 0.1);
  }
}

.rew-order-table-item__row {
  display: flex;
  justify-content: space-between;
}

// for the table rows
.gray-background {
  background: #f9f9f9;
}

.item-divider.item-divider {
  background: $rew-alto;
  color: $rew-gray;

  font-size: 14px;
  padding-bottom: 6px;

  .rew-order-list__orders__table__history-order:not(:first-child) & {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cecece+0,e0e0e0+32 */
    background: #cecece; /* Old browsers */
    background: -moz-linear-gradient(top, #cecece 0%, #e0e0e0 32%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #cecece 0%, #e0e0e0 32%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #cecece 0%,
      #e0e0e0 32%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cecece', endColorstr='#e0e0e0',GradientType=0 ); /* IE6-9 */
  }
}

.rew-order-table-item {
  // override ionic
  &.item {
    border: none;
  }
}

.rew-order-list__orders__table__cell--show_only_on_mobile {
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
}

.rew-order-list__orders__table__cell__amount {
  //line-height: $table-font-size;
}

.rew-order-list__orders__table__cell__amount__currency {
  font-size: $table-font-size - 1px;
}

.rew-order-list__orders__table__cell__amount__number {
  font-weight: bold;
  // vertical-align: text-bottom;
}
