@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-calculator-marketing .rew-modal-page {
  // to allow it to be hosted in the www.rewire.to app
  min-width: 225px;
}

.rew-calculator-marketing__spacing {
  margin-top: 45px;
}

.rew-calculator-marketing__header1 {
  margin: 0px 0px 10px 0px;
}

.rew-calculator-marketing__header2 {
  margin-bottom: 0;
  font-weight: bold;
}

.rew-calculator-marketing__sign-up-text {
  margin-top: 10px;
}

.rew-calculator-marketing__rates-container.rew-pane {
  background-color: rgba(255, 255, 255, 0.14);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  margin-bottom: -2px;
  padding: 5px;
}

.rew-calculator-marketing__rates-container__header {
  color: $rew-silver-chalice;
}

.rew-calculator-marketing__rates-container__rates {
  .rew-rates__first-row {
    padding-top: 3px;
    color: #cfcfcf;
    font-size: 16px;
  }

  .rew-rates__second-row {
    color: #cfcfcf;
  }

  &.rew-calculator-marketing__rates-container__rates--big-fonts {
    .rew-rates__first-row {
      font-size: 21px;
    }

    .rew-rates__second-row {
      font-size: 15px;
    }
  }
}

.rew-calculator-marketing .rew-calculator__cell.rew-calculator__cell {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.rew-calculator-marketing .rew-calculator__cell:last-child {
  border-bottom-right-radius: 4px;
}

.rew-calculator-marketing .rew-calculator__cell:first-child {
  border-bottom-left-radius: 4px;
}

.rew-calculator-marketing .rew-transaction-details__details-item {
  color: $rew-gray;
}

.rew-calculator-marketing__sign-up-button.mdl-button--raised.mdl-button--colored:hover,
.rew-calculator-marketing__sign-up-button.mdl-button--raised.mdl-button--colored {
  background-color: $rew-primary;
}

@media screen and (min-width: 600px) {
  $marketing-font-size-for-desktop: 24px;
  $marketing-line-height-for-desktop: $marketing-font-size-for-desktop + 5px;

  .rew-info__body {
    &.rew-calculator-marketing__header1.rew-calculator-marketing__header1 {
      font-size: $marketing-font-size-for-desktop;
      line-height: $marketing-line-height-for-desktop;

      margin-top: 60px;
    }

    &.rew-calculator-marketing__header2.rew-calculator-marketing__header2 {
      font-size: $marketing-font-size-for-desktop;
      line-height: $marketing-line-height-for-desktop;
      font-weight: normal;

      margin-top: 25px;
    }

    &.rew-calculator-marketing__sign-up-text.rew-calculator-marketing__sign-up-text {
      font-size: $marketing-font-size-for-desktop;
      line-height: $marketing-line-height-for-desktop;

      margin-top: 25px;
    }
  }

  .rew-modal-page__narrow-logo.rew-modal-page__narrow-logo {
    height: 30px;
  }
}
