@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-debit-card-verification {
  &__keyboard {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__image {
    background-image: url(./card-activate.svg);
    display: inline-block;
    width: 80px;
    height: 51px;

    margin-top: 10px;
    align-self: center;

    &#{&}--loading {
      position: relative;
      background-image: none;
    }
  }
}
