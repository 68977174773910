$phone-width: 767px;

$largePhone: '(min-width: 360px)';
$phone: '(max-width: 767px)';
$tablet: '(min-width: 768px) and (max-width: 991px)';
$laptop: '(min-width: 992px) and (max-width: 1199px)';
$desktop: '(min-width: 1200px)';

@mixin low-height-device {
  @media (max-height: 481px) {
    @content;
  }
}

// If you use both $desktop and $desktop-xl-only for media queries, make sure
// $desktop-xl-only comes after the $desktop query, otherwise $desktop will
// override $desktop-xl-only
$desktop-xl-only: '(min-width: 1650px)';

$desktop-xl-width: 1650px;

$tablet-width: 768px;
$desktop-width: 1024px;

@mixin phone {
  @media #{$phone} {
    @content;
  }
}

@mixin large-phone {
  @media #{$largePhone} {
    @content;
  }
}

@mixin phone-tablet {
  @media #{$phone, $tablet} {
    @content;
  }
}

@mixin not-phone {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media #{$tablet} {
    @content;
  }
}

@mixin laptop-desktop {
  @media #{$laptop, $desktop} {
    @content;
  }
}

@mixin laptop {
  @media #{$laptop} {
    @content;
  }
}

@mixin desktop {
  @media #{$desktop} {
    @content;
  }
}
