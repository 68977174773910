@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$page-margin: 9px;

.rew-page {
  background-color: $rew-alto;
  padding-bottom: $page-margin;

  // for desktop
  max-width: $phone-width;
  margin: auto;
}

.rew-page--full {
  min-height: 100%;
  //height: 100%;
}
