@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.major-modal.popup-container {
  $side-padding: 15px;
  $location-total-height: 20px;

  background-image: linear-gradient(to bottom, #163357, #000233);
  background-image: $rew-primary-dark;

  .popup {
    @include phone {
      left: 15px;
      top: 15px;
    }

    background-color: $rew-white;
    position: fixed;
    padding: 0;
    border-radius: 5px;

    width: calc(100% - #{$side-padding * 2});
    height: calc(100% - #{$side-padding * 2});

    max-width: 388px;
    max-height: 652px;
  }

  .popup-head {
    display: none;
  }

  .c-modal {
    margin: 0;

    &,
    &__content {
      height: 100%;
    }
  }

  .popup-body {
    padding: 14px 0;
    height: 100%;
  }

  .rew-multipart-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__content {
      padding-top: 22px;
      height: calc(100% - #{$location-total-height});
    }

    &__location {
      margin-bottom: 0;
    }
  }
}
