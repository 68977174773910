@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-acc-upgrade-card {
  padding: 22px 18px 15px;

  &__upgrade-button {
    display: block;
    margin: 18px auto 0;
    text-align: center;
  }
}
