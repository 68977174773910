@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-l-card {
  @include phone {
    margin-left: $rew-page-padding-side;
    margin-right: $rew-page-padding-side;
  }
}
