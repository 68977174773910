@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
@import '../rew-wallet-item';

.rew-wallet-basic-item {
  @include rew-wallet-item;

  &__order-status {
    margin-top: 6px;
  }
}
