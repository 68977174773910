@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-card {
  display: block;
  position: relative;

  &--loading &__content-container,
  &--loading &__button-container {
    opacity: 0.2;
  }
}

.rew-card__content-container {
  display: flex;
}

.rew-card.padded {
  padding-top: 15px;
  padding-bottom: 15px;
}

// override rew-pane margin
.rew-card.rew-pane {
  margin-top: 15px;
}

.rew-card__content-container__content {
  flex: auto;
  color: $rew-silver-chalice;

  overflow: hidden;
}

.rew-card__content-container__content-block {
  display: flex;
}

.rew-card__content-container__content-block-main {
  // Shrinks but doesn't expands
  flex: 1 1 auto;

  overflow: hidden;
}

.rew-card__content-container__content-block-side {
  // Expands but doesn't shrink
  flex-shrink: 0;
}

.rew-card__content-container__content-block-side__lower {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.rew-card.mdl-js-ripple-effect {
  cursor: pointer;
}

.rew-card__content-container__emblem {
  border: 0;

  margin-right: $rew-pane-padding-sides;
  font-size: $rew-card-emblem-font-size;
  font-family: $rew-font-family-regular;

  color: $rew-cerulean;

  flex-shrink: 0;

  background-color: transparent;

  height: $rew-card-emblem-font-size + 11px;
  width: $rew-card-emblem-font-size + 11px;

  &.rew-card__content-container__emblem.emblem-circle {
    border-radius: 50%;
    background-color: $rew-primary;
    color: $rew-white;

    padding-right: 0px; //iphone bug fix
    padding-left: 0px; //iphone bug fix
  }

  // Add a set of background colors:
  &.rew-card__content-container__emblem--color-teal {
    background-color: teal;
  }
}

.rew-card__button-container .mdl-button {
  margin-top: 10px;
}
