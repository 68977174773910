@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-monthly-orders-graph {
  padding-top: 10px;
  padding-bottom: 10px;
}

.axis {
  font-size: 10px;
  color: $rew-gray;

  path,
  line {
    fill: none;
    // stroke: #000;
    // shape-rendering: crispEdges;
  }
}
