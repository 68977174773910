@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-card-wrapper {
  padding: 15px;
  text-align: center;
  color: $rew-gray;

  &__body {
    max-width: 365px;
    margin: auto;
  }
}
