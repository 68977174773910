@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-load-money-form {
  flex-direction: column;
  display: flex;
  align-items: center;

  &__img {
    width: 200px;
    height: 123px;
    object-fit: contain;

    &--sofort {
      background-image: url(./load-sofort.svg);
    }

    &--instant {
      background-image: url(./load-instant.svg);
    }
  }

  &__form {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__iframe {
    width: 300px;
    height: 400px;
  }

  &__text {
    display: block;
    max-width: 260px;
    line-height: 0.3;
    text-align: center;
    margin-bottom: 13px;

    &--bold {
      font-weight: bold;
    }
  }
}
