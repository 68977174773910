@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-group__header {
  font-weight: bold;
  font-size: 14px;

  color: $rew-gray;
  margin-bottom: -10px;
}

.rew-group {
  padding: 2px 6px;
  margin: -5px -5px;
  margin-bottom: 0px;

  border-radius: $rew-pane-border-radius;
  //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
