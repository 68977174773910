@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-modal-page__link {
  color: $rew-light-blue;
  display: block;
}

.rew-modal-page__description {
  color: $rew-white;
}

.rew-modal-page__narrow-logo {
  height: 23px;
}

.rew-modal-page__header_narrow-logo-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  text-align: center;
  padding: 10px;
  background-color: #091523;
}

.rew-modal-page__topImage {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  margin-bottom: -5px;
}

.rew-modal-page__submit-button-container {
  margin-top: 15px;
}

.rew-modal-page__submit-button {
  display: block;
  margin: auto;
}
