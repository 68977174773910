@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-upload-receipt__image-uploader-container {
  margin-top: 10px;
  margin-bottom: -8px;

  max-width: 300px; // for desktop
  margin: auto;

  // these fixes are to prevnet the hopping between our sample images
  // and the user images which their height can be smaller than
  // our sample images.
  @media screen and (max-width: 300px) {
    min-height: 175px;
  }
  @media screen and (min-width: 300px) and (max-width: 330px) {
    min-height: 180px;
  }
  @media screen and (min-width: 330px) and (max-width: 350px) {
    min-height: 190px;
  }
  @media screen and (min-width: 350px) {
    min-height: 200px;
  }
}

.rew-upload-receipt__top-text-container {
  margin-bottom: 5px;
}

.rew-upload-receipt__image-uploader-container__explanation-text__red-dot {
  height: 5px;
  margin-right: 3px;
}

.rew-over-populdated-modal.rew-upload-receipt-modal {
  &.rew-modal.popup-container .popup {
    padding-top: 6px;
  }
}

.rew-upload-receipt__form__reference-tooltip__image {
  margin-top: -130px;
  height: 180px;

  @media screen and (max-width: 600px) {
    margin-top: -150px;
    height: 140px;
  }

  @media screen and (max-width: 330px) {
    margin-top: -155px;
    height: 120px;
  }
}

.rew-upload-receipt__form__reference-tooltip {
  position: absolute;
  z-index: 10;
  right: -25px; // so it will be outside of the popup
}

.rew-upload-receipt {
  .mdl-button--colored[disabled][disabled],
  .mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
    color: white;
    box-shadow: none;
  }
}

.bigEntrance.ng-enter {
  animation-name: bigEntrance;
  -webkit-animation-name: bigEntrance;

  animation-duration: 2s;
  -webkit-animation-duration: 2s;

  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;

  visibility: visible !important;
}

@keyframes bigEntrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}

@-webkit-keyframes bigEntrance {
  0% {
    -webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    -webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    -webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}
