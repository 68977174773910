@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-pane {
  $p: &;

  border-radius: $rew-pane-border-radius;
  background-color: $rew-white;
  padding: $rew-pane-padding-sides;

  &#{&}--of-blocks {
    padding: 0;
  }

  &__block {
    padding: $rew-pane-padding-sides;

    & + & {
      border-top: solid 1px $rew-silver;
    }
  }

  &.rew-pane--no-shadow {
    border-radius: 0;
    box-shadow: 0 0 0 0;
  }

  &.rew-pane--rounded {
    border-radius: $rew-pane-border-modal-radius;
  }

  &.rew-pane--modal {
    padding-right: $rew-pane-padding-modal-sides;
    padding-left: $rew-pane-padding-modal-sides;
  }

  &.rew-pane--full {
    min-height: 100%;
    margin-top: 0;

    width: 100%;
    margin-bottom: 10px; /* Used to support big screens (see home welcome screen) */
  }

  .rew-page--full &.rew-pane--full {
    margin-bottom: 0px; /* get the margin-bottom from the rewire-page--full */
  }

  &.rew-pane--first {
    margin-top: 0;
  }

  &.rew-pane--light {
    background-color: $rew-wild-sand;
  }

  &.rew-pane--white {
    background: $rew-white;
  }

  &.rew-pane--disabled {
    background: rgba(255, 255, 255, 0.5);
  }

  &.rew-pane--modal {
    & .rew-modal__header {
      color: $rew-subheader-color;
    }
    & .rew-modal__sub-header {
      color: $rew-subheader-color;
      font-size: $rew-subheader-font-size;
    }

    & .rew-page-header {
      padding-top: 10px;
      padding-bottom: 5px;
    }
  }
}
