@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-modal {
  & .rew-modal__header,
  & .rew-modal__sub-header,
  & .popup-title,
  & .popup-sub-title {
    color: $rew-gray;
    text-align: center;
  }

  & .popup-title,
  & .rew-modal__header {
    font-size: 20px;
  }

  & .popup-head {
    display: none;

    // if we decide to show the head, make it behave
    // like the rest of the modal
    border-radius: $rew-pane-border-modal-radius;
  }

  &.popup-container .popup,
  .rew-modal__content-container {
    border-radius: $rew-modal-border-radius;
    background: $rew-white;
  }

  // override ionic default behavior
  &.rew-over-populdated-modal.popup-container {
    & .popup {
      margin-top: 10px;
      max-height: 95%;
      max-width: 700px;
      width: 95%;

      & .popup-body {
        padding-right: 5px;
        padding-left: 5px;
      }
    }

    // override this behavior only for ionic
    & .rew-modal__header-container {
      margin: 2px 0px 7px 0px;
      padding-top: initial; //overriding default modal's behavior
    }
  }
}
