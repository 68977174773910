@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-upload-card {
  // background: $rew-white;
}

.rew-upload-card__content {
  padding: 10px 0px;

  display: flex;
  align-content: row;
  justify-content: space-between;
}

.rew-upload-card__content__right {
  flex-grow: 0;
  margin-left: 10px;

  min-width: 70px; // for iphone
}

.rew-upload-card__content__left {
  flex-grow: 1;
  flex-shrink: 1;
}
