@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-bills__content-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.rew-bills__content-container__request-amount {
  color: $rew-gray;
  margin-top: -14px;
  font-size: 19px;
  margin-bottom: 10px;
}

.rew-bills__content-container__next-button {
  margin-top: 5px;
}

.rew-bills__button-container {
  text-align: center;
  margin-top: 15px;
}
