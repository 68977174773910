@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-smart-transfer {
  position: relative;

  &__checkbox {
    flex: 0;

    align-self: center;
  }

  & &__checkbox {
    padding-right: 4px;
  }

  &__text {
    flex: 1;

    &.mdl-checkbox__label {
      line-height: 1.3;
    }
  }

  &__title {
    font-weight: bold;
  }

  &__refund {
    text-align: center;
    font-weight: bold;
    color: $rew-feijoa;
  }

  &__new-label {
    position: absolute;
    right: -($rew-pane-padding-sides + $rew-label-offset);
    top: 0;
    transform: translateY(-50%);

    transform-origin: 100% 100%;
  }

  // Patch to have it properly displayed
  .mdl-checkbox__ripple-container {
    left: -14px;
  }

  .mdl-checkbox {
    height: auto;
  }

  .mdl-checkbox__label {
    margin-left: 5px;
  }

  .mdl-checkbox__box-outline,
  .mdl-checkbox__focus-helper {
    top: 50%;
    transform: translateY(-50%);
  }
}
