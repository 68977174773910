@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-modal {
}

.rew-modal__link-container {
  margin-top: 15px;
  margin-bottom: 0px;
  text-align: center;
}

.rew-modal__header,
.rew-modal__sub-header {
  color: $rew-gray;
  text-align: center;
}

.rew-modal__header {
  font-size: 20px;
}

.rew-modal__sub-header {
  font-size: 11px;
  //margin-top: 2px;
}

.rew-modal__header-container {
  margin-top: 40px;
  padding-top: 25px;
}

.rew-modal__content-container {
  border-radius: $rew-pane-border-modal-radius;
  background: $rew-white;

  padding-right: $rew-pane-padding-modal-sides;
  padding-left: $rew-pane-padding-modal-sides;
}

.rew-modal__dark-background {
  background: rgba(0, 0, 0, 0.5);
  padding-right: $rew-pane-padding-modal-sides;
  padding-left: $rew-pane-padding-modal-sides;
}

.rew-modal--full {
  min-height: 100%;
  margin-top: 0;
  margin-bottom: 10px; /* Used to support big screens (see home welcome screen) */
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 10px;
}

.rew-modal--full-width {
  &.popup-container .popup {
    width: 90%;
  }
}

.rew-modal-img {
  max-width: 80%;
  max-height: 190px; // see power of attorney

  display: block;

  margin: auto;
  margin-top: 15px;
  margin-bottom: 10px;
}

.rew-modal-img.rew-modal-img--wide {
  max-width: 100%;
}

.popup-container.rew-modal__background-darker {
  background: rgba(0, 0, 0, 0.7);
}

.rew-modal__button-container .mdl-button:nth-of-type(2n) {
  margin-left: 5px;
}

.rew-modal__button-container {
  margin-top: 12px;
  display: flex;
  align-content: space-between;
}

.rew-modal__agree-container {
  margin-top: 10px;

  .mdl-checkbox__label {
    color: $rew-gray;
  }

  &.rew-modal__agree-container--invalid {
    .mdl-checkbox__label {
      color: $rew-red;
    }
  }

  .mdl-checkbox__box-outline {
    border-color: $rew-gray;
  }

  &.rew-modal__agree-container--invalid {
    .mdl-checkbox__box-outline {
      border-color: $rew-red;
    }
  }
}

.rew-modal-page__submit-button {
  flex-grow: 1;
  text-transform: capitalize;

  & + & {
    margin-left: 15px;
  }
}

.rew-error-modal.level-2-error {
  .popup-body {
    .rew-info__header {
      color: $rew-red;
    }
  }
}

.rew-modal__content-container,
.rew-modal-page__input-area,
.rew-sign-up__input-area {
  padding-top: 0px;
  padding-bottom: 15px;
}

.rew-modal {
  margin-top: -3px; // used to reduce the visual impact of the text's line height

  &.popup-container .popup {
    width: 90%;
    max-width: 350px;
    min-width: 250px;
    padding: 6px 6px 6px 6px;

    position: relative;
  }

  .rew-info__body {
    // used in error service (rates change)
    white-space: pre-line;
  }
}

.rew-modal__close {
  top: 0;
  right: 0;
  position: absolute;
}

.mdl-button.rew-modal__close__button {
  margin-right: -14px;
  padding: 0;
}

.mdl-button.rew-modal__close__button:hover {
  background: transparent;
}
