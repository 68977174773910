@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-limit-card {
  // background: $rew-white;
}

.rew-limit-card__content {
  display: flex;
  align-content: row;
  justify-content: space-between;

  line-height: normal;
}

.rew-limit-card__content__left {
  flex-grow: 1;
  flex-shrink: 1;
}

.rew-limit-card.rew-pane {
  border-left: 5px $rew-carnation solid;
  padding-right: 6px;
}

.rew-limit-card__content__right {
  flex-grow: 0;
  flex-shrink: 0;
}

.rew-limit-card__content--limit-reached {
  color: $rew-carnation;
}

.rew-limit-card .rew-limit-card__more-info {
  align-self: flex-end;
}

.rew-limit-card__more-info {
  color: $rew-turquoise-blue;
  font-size: 25px;
  margin-bottom: -9px;
  padding-right: 2px;
}

.rew-limit-card {
  cursor: pointer;
}

.rew-limit-card .rew-info__header {
  margin-bottom: 1px;
}
