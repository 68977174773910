@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$rew-table-font-size: 13px;
$rew-table-line-height: 1.3;
$rew-table-lines-to-show: 2;

.rew-table__cell {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100px; // used to break lines in pc
  min-height: $rew-table-font-size * $rew-table-line-height * $rew-table-lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: $rew-table-font-size;
  line-height: $rew-table-line-height;
  -webkit-line-clamp: $rew-table-lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  // override ionic
  white-space: pre-line;

  // to make the table aligned:
  flex-grow: 1;

  //
  color: $rew-gray;
}

@media screen and (max-width: 600px) {
  .rew-table__cell {
    // used to break lines in mobile
    max-width: 60px;

    &.rew-table__cell--hide-on-mobile {
      display: none;
    }
  }
}

@media screen and (min-width: 600px) {
  .rew-table__cell__vertical-aligned-content {
    height: $rew-table-font-size * $rew-table-line-height * $rew-table-lines-to-show;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
  }
}

.rew-table__cell--center {
  text-align: center;
}

.rew-table__cell__vertical-aligned-content {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.rew-table__cell--single-line {
  line-height: $rew-table-line-height * $rew-table-lines-to-show;
}

.rew-table__cell.rew-table__cell__date {
  max-width: 60px;
  //padding: 0px 10px;
  flex-basis: 35px;
  flex-grow: 0;
}

.rew-solid-color-scheme {
  background: #7cc576;

  &.rejected,
  &.expired {
    background: rgb(239, 102, 83); //#EF6653
  }
  &.canceled {
    background: #d3d3d3;
  }
  &.in-process {
    background: rgb(100, 187, 233); // #64BBE9;
  }
  &.awaiting-deposit {
    background: rgb(241, 190, 79); // #F1BE4F;
  }
}

.rew-half-transparent-color-scheme {
  &.rejected {
    background: rgba(239, 102, 83, 0.1);
  }
  // &.in-process {
  //   background: rgba(100, 187, 233, 0.1);
  // }
  &.awaiting-deposit {
    background: rgba(241, 190, 79, 0.1);
  }
}

.rew-table__cell--show-only-on-mobile {
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
}

.rew-table__cell--show-only-on-desktop {
  display: none;
  @media screen and (min-width: 600px) {
    display: block;
  }
}

.rew-table__cell__amount {
  min-width: 40px;

  @media screen and (min-width: 600px) {
    min-width: 80px;
  }
}

.rew-table__cell__amount__currency {
  font-size: $rew-table-font-size - 1px;
}

.rew-table__cell__amount__number {
  font-weight: bold;
  // vertical-align: text-bottom;
}

.rew-table__cell--fixed-size {
  flex-grow: 0;
}

.rew-table__cell.rewire-icons {
  flex-basis: 20px;
}
