@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rewire-external-extensions .mdl-textfield {
  & ::-webkit-input-placeholder {
    color: transparent;
  }

  &.is-focused ::-webkit-input-placeholder {
    transition: color 1s;
    color: $rew-dirty-textfield-label-color;
  }

  &.in-focused.is-dirty ::-webkit-input-placeholder {
    color: transparent;
  }

  // hack to fix this input duplication issue in ionic:
  // http://forum.ionicframework.com/t/textarea-and-input-display-problems/4161/23
  & .cloned-text-input {
    display: none !important;
  }
}

// fixed the date field in chrome
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.unstyled::-webkit-clear-button {
  /* Removes blue cross */
  -webkit-appearance: none;
  margin: 0;
}

input[type='date'] {
  &::-webkit-datetime-edit,
  &::-webkit-datetime-edit-fields-wrapper,
  &::-webkit-datetime-edit-ampm-field,
  &::-webkit-datetime-edit-day-field,
  &::-webkit-datetime-edit-hour-field,
  &::-webkit-datetime-edit-millisecond-field,
  &::-webkit-datetime-edit-minute-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-second-field,
  &::-webkit-datetime-edit-week-field,
  &::-webkit-datetime-edit-year-field {
    font-size: 18px; // same height as other text fields
  }

  min-height: 29px; // a fix for ios safari and app - date input have height of 0
  // breaking the mdl button look

  .is-focused &,
  .is-dirty & {
    &::-webkit-datetime-edit,
    &::-webkit-datetime-edit-fields-wrapper,
    &::-webkit-datetime-edit-ampm-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-hour-field,
    &::-webkit-datetime-edit-millisecond-field,
    &::-webkit-datetime-edit-minute-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-second-field,
    &::-webkit-datetime-edit-week-field,
    &::-webkit-datetime-edit-year-field {
      transition: visibility 1s;
      visibility: visible;
    }
  }
}
