@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.upload-kyc-doc-form {
  &__upload-id {
    max-width: 260px;
    overflow: hidden;

    display: block;
    margin: auto;

    &--short {
      height: 86px;

      .rew-image-uploader__image.rew-image-uploader__image--error {
        height: 95px;
      }
    }

    & + & {
      margin-top: 10px;
    }
  }

  &__form {
    display: block;
    margin-top: 32px;
  }

  &__checkbox-text {
    line-height: 24px;

    &--ok {
      :not(.is-checked).is-invalid + * & {
        display: none;
      }
    }

    &--error {
      display: none;

      :not(.is-checked).is-invalid + * & {
        display: block;
      }
    }
  }

  // Remove this rule after &__agree is uncommented
  &__declare {
    display: block;
    margin-top: 32px;
  }

  &__text {
    color: $rew-another-gray;
    font-size: $rew-font-size-10;
    line-height: 12px;

    strong {
      font-weight: bold;
    }
  }

  &__checkbox {
    display: flex;
    position: absolute;
    bottom: 47px;
  }

  &__approve-terms-text {
    margin-top: 15px;
    display: block;
  }
}
