@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-breadcrumbs {
}

.rew-breadcrumbs__crumb {
  padding-right: 5px;
  position: relative;

  z-index: 10;

  &:hover {
    cursor: pointer;
  }
}

.rew-breadcrumbs__seperator {
  padding-right: 10px;
}

.rew-breadcrumbs_crumb-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}
