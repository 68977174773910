@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-text {
  font-size: $rew-font-size-15;
  line-height: 1.2;
  color: $rew-another-gray;
  text-align: center;

  &--align-left {
    text-align: left;
  }

  &#{&}--error {
    color: $rew-deep-blush;
  }
}
