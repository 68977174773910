@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-pdf-generator__canvas {
  width: 100%;
  height: auto;

  min-height: 300px; // for the loading time
}

.rew-pdf-generator__signature__buttons_container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.rew-pdf-generator__fields__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rew-pdf-generator__fields__form__read-only-container__row {
  display: flex;
}

.rew-pdf-generator__fields__form__read-only-container__row__item {
  flex-basis: 50%;
}

.rew-pdf-generator__fields__form__read-only-container__row__item__change-button {
  color: $rew-links-color;
}
