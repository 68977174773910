@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-screen-keyboard-button {
  $side-length: 50px;

  cursor: pointer;
  display: inline-block;
  border: solid 1px $rew-mint-tulip;
  border-radius: 100%;
  text-align: center;
  width: $side-length;
  height: $side-length;
  line-height: $side-length;

  transition: all 0.5s ease-out;

  &:active {
    background-color: $rew-mint-tulip;
    transition: all 0.05s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &#{&}--text-btn {
    border: 0;
  }
}
